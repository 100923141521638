.face-framed-gallery {
    background: var(--bg-lighter);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    padding-top: 250px;
}

.face-framed-gallery .content {
    padding: 45px 0px;
    display: flex;
    flex-direction: column;
    gap: 45px;
    max-width: 1220px;
}

.face-framed-gallery .content .category {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.face-framed-gallery .content .category .category-name {
    display: flex;
    gap: 10px;
}

.face-framed-gallery .content .category .category-name h2 {
    color: var(--light-light-color);
    font-weight: var(--font-weight-regular);
    font-size: 1.5em;
}

.face-framed-gallery .content .category .category-name .underline hr {
    background: var(--primary-color);
    border-radius: 5px;
    border: none;
    height: 5px;
    width: 100%;
}

.face-framed-gallery .content .category .items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

@media (max-width: 991px) {
    .face-framed-gallery {
        padding-top: 150px;
        gap: 8px;
    }
}