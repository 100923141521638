.banner {
    position: relative;
    width: 100%;
    height: 900px;
    /* background-image: linear-gradient(180deg, #0A0F15 1%, rgba(10, 15, 21, 0.1) 100%), url("../../assets/imgs/gallery/sage/1.webp"); */
    background-image: url("imgs/banner.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* .banner video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    object-fit: cover;
    z-index: -10000;
} */

.banner .fourth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding-top: 120px;
}
.banner .fourth .title {
    color: var(--primary-color);
    font-weight: 600;
    font-family: 'ultra-condensed';
    font-size: 112px;
    line-height: 70px;
    padding-bottom: 16px;
    text-shadow: 1px 5px 4px var(--secondary-light-color);
}
.banner .fourth .subtitle {
    /* color: var(--primary-color); */
    color: var(--light-dark-color);
    font-size: 24px;
    font-weight: 600;
    text-shadow: 1px 5px 4px var(--secondary-light-color);
    /* background: rgba(29, 29, 29, 0.85); */
    padding: 4px 8px;
    border-radius: 5px;
    margin-top: 16px;
}
.banner .fourth .description {
    color: var(--light-dark-color);
    font-size: 24px;
    font-weight: 400;
}
.banner .fourth .turnaround {
    /* color: var(--primary-dark-color); */
    color: var(--light-dark-color);
    font-size: 32px;
    font-weight: 400;
    text-shadow: 1px 5px 4px var(--secondary-light-color);
    /* background: rgba(29, 29, 29, 0.85); */
    padding: 2px 8px;
    border-radius: 5px;
}
.banner .fourth .hours,
.banner .fourth .holiday-hours,
.banner .fourth .new-address {
    /* color: var(--primary-dark-color); */
    color: var(--light-dark-color);
    font-size: 18px;
    font-weight: 400;
    text-shadow: 1px 5px 4px var(--secondary-light-color);
    /* background: rgba(29, 29, 29, 0.85); */
    padding: 2px 8px;
    border-radius: 5px;
}
.banner .fourth .holiday-hours,
.banner .fourth .new-address {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 600;
    animation: pulse 1.5s infinite;
}

.banner .fourth .cta {
    margin-top: 56px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.04);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.banner .fourth form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}
.banner .fourth form input {
    width: 200px;
}

.banner .content {
    display: flex;
    justify-content: center;
    padding-top: 320px;
    max-width: 1000px;
}

.banner .content .text {
    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 1;
}

.banner .content .text .title {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.banner .content .text .title hr {
    height: 5px;
    width: 195px;
    background: var(--primary-color);
    border: none;
    border-radius: 5px;
}

.banner .content .text .title h1 {
    color: var(--light-light-color);
    font-weight: 600;
    font-family: 'ultra-condensed';
    font-size: 64px;
    line-height: 70px;
}

.banner .content .text .subtitle {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.banner .content .text .subtitle p {
    font-size: 24px;
    line-height: 38px;
    font-weight: 200;
}

.banner .content .text .subtitle h2 {
    font-size: 18px;
    line-height: 29px;
    font-weight: 300;
}

.banner .content .text .list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.banner .content .text .list .item {
    display: flex;
    align-items: center;
    gap: 12px;
}

.banner .content .text .list .item hr {
    height: 5px;
    width: 20px;
    background: var(--primary-color);
    border: none;
    border-radius: 5px;
}

.banner .content .text .list .item p {
    font-size: 16px;
    font-weight: 200;
    line-height: 26px;
    color: var(--light-light-color);
}

.banner .content .text .list .item h2 {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    color: var(--light-light-color);
}

.banner .content .text .numbers {
    display: flex;
    gap: 40px;
    padding: 35px 0;
}

.banner .content .text .numbers .ad {
    display: flex;
    flex-direction: column;
}

.banner .content .text .numbers .ad .number {
    font-size: 2.5em;
    font-weight: 300;
}

@media (max-width: 1439px) {
    /* .banner {
        height: 1000px;
    }
    
    .banner::after {
        height: 1000px;
    } */
}

@media (max-width: 1239px) {
    .banner .content {
        padding-top: 280px;
    }
}

@media (max-width: 904px) {
    .banner .content {
        padding-top: 260px;
    }
    .banner .fourth {
        align-items: flex-start;
    }
    .banner .fourth .title {
        font-weight: 600;
        font-size: 80px;
        line-height: 88px;
        text-shadow: 1px 3px 3px var(--secondary-light-color);
    }
    .banner .fourth .subtitle {
        font-size: 24px;
        /* text-shadow: 1px 3px 3px var(--secondary-light-color); */
        padding: 4px 22px;
        margin-top: 0px;
    }
    .banner .fourth .description {
        color: var(--light-dark-color);
        font-size: 24px;
        font-weight: 400;
    }
    .banner .fourth .turnaround {
        /* color: var(--primary-dark-color); */
        font-size: 28px;
        padding: 2px 22px;
    }
    .banner .fourth .hours {
        /* color: var(--primary-dark-color); */
        color: var(--light-light-color);
        font-size: 16px;
        font-weight: 400;
        padding: 2px 22px;
        /* text-shadow: 1px 3px 3px var(--secondary-light-color); */
    }
    .banner .fourth .holiday-hours,
    .banner .fourth .new-address {
        font-size: 16px;
        padding: 0px 22px;
    }
}

@media (max-width: 599px) {
    .banner {
        /* height: 860px; */
        padding: 0px 32px;
        height: 1000px;
    }
    
    .banner::after {
        height: 860px;
    }

    .banner .fourth {
        gap: 24px;
    }

    .banner .fourth .cta {
        width: 100%;
        display: flex;
        justify-content: right;
        margin-top: 56px;
    }

    .banner .content {
        padding-top: 160px;
    }

    .banner .content .text .title h1 {
        font-weight: 600;
        font-size: 48px;
        line-height: 53px;
    }

    .banner .content .text .subtitle h2 {
        font-size: 18px;
        line-height: 29px;
    }

    .banner .content .text .list {
        gap: 12px;
    }
}