.TextArea textarea {
    height: 256px;
    color: var(--primary-dark-color);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 15px;
    border: none;
    width: 100%;
    font-size: 0.8em;
    font-weight: 200;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    resize: none;
}

.TextArea textarea::placeholder {
    color: var(--primary-dark-color);
}

.TextArea textarea:focus {
    border: 1px solid var(--primary-dark-color);
    outline: none;
}