.gallery-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-darker);
    gap: 45px;
    padding-top: 250px;
}

.gallery-intro .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* width: 842px; */
    width: 100%;
    position: relative;
    gap: 0;
}

.gallery-intro .item .info {
    display: flex;
    flex-direction: column;
    background: rgba(66, 66, 66, 0.2);
    /* border: 1px solid rgba(252, 231, 174, 0.2); */
    border-radius: 5px;
    gap: 24px;
    padding: 100px 64px 20px 64px;
    width: 100%;
    /* align-items: center; */
    position: relative;
    margin-top: -85px;
    max-width: 1040px;
}

.gallery-intro .item .info h2 {
    color: var(--primary-color);
    font-size: var(--h3-desktop-font-size);
    font-weight: var(--font-weight-regular);
    text-align: center;
}

.gallery-intro .item .info p {
    color: var(--light-light-color);
    font-size: 16px;
    line-height: 26px;
    font-weight: var(--font-weight-regular);
    text-align: justify;
    hyphens: auto;
    font-weight: 200;
}

.gallery-intro .item .info .cta {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.gallery-intro hr {
    width: 5px;
    height: 25px;
    background: var(--primary-dark-color);
    border-radius: 5px;
    border: none;
}

@media (max-width: 991px) {
    .gallery-intro {
        gap: 35px;
        padding: 150px 0 35px 0;
    }

    .gallery-intro .item {
        padding: 0 20px 0 20px;
    }

    .gallery-intro .item .info {
        padding: 100px 24px 20px 24px;
    }
}