.heading {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 576px;
}

.heading::after {
    content: "";
    background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%), url("../assets/imgs/heading.webp");
    filter: blur(1px);
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 576px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.heading h1 {
    font-family: "ultra-condensed";
    width: 820px;
    font-weight: 300;
    font-size: 56px;
    line-height: 73px;
    text-align: center;
    color: var(--light-dark-color);
    z-index: 1;
}

@media(max-width: 991px) {
    .heading h1 {
        font-size: 48px;
        line-height: 62px;
        padding: 20px;
    }
}