.footer {
    padding: 50px 100px;
    background: var(--bg-darker);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 30px;
}

.footer .content .col {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 400px;
}

.footer .content .col .title,
.footer .content .col .subtitle {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.footer .content .col .subtitle h2 {
    color: var(--light-light-color);
    font-size: 18px;
    font-weight: 400;
}

.footer .content .col .title h2 {
    font-family: 'ultra-condensed','Poppins', sans-serif;
    color: var(--light-light-color);
    font-size: 40px;
    line-height: 52px;
}

.footer .content .col .title hr,
.footer .content .col .subtitle hr
 {
    width: 111px;
    height: 5px;
    border: none;
    border-radius: 5px;
    background: var(--primary-color);
}

.footer .content .col .subtitle hr {
    width: 35px;
}

.footer .content .col p {
    color: var(--light-light-color);
    font-size: 16px;
    font-weight: 200;
    line-height: 26px;
}

.footer .content .col a {
    text-decoration: none;
    color: var(--light-light-color);
}

.footer .content .col .infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.footer .content .col .infos .info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer .col .infos i {
    font-size: 20px;
    width: 20px;
    color: var(--primary-light-color);
    text-align: center;
}

.footer .content .col .infos a {
    text-decoration: none;
    color: var(--light-light-color);
}

.footer .content .col .infos .info h3 {
    font-size: 16px;
    font-weight: 200;
}

.footer .content .col .infos .hours {
    display: flex;
    flex-direction: column;
}

.footer .krosson {
    color: var(--light-light-color);
    text-decoration: none;
    font-size: 0.8em;
    font-weight: 200;
}

.footer .krosson a {
    font-size: 1em;
}

/* ########################################## */
/* ############# START WARRANTY ############# */
/* ########################################## */
/* The Modal (background) */
.warranty-modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 130px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    /* overflow: auto; Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
  
/* Modal Content */
.warranty-modal-content {
    position: relative;
    /* background-color: var(--bg-lighter); */
    background: rgba(23, 25, 29);
    margin: auto;
    margin-bottom: 100px;
    padding: 0;
    border: 1px solid rgba(252, 231, 174, 0.2);
    width: 80%;
    height: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}
  
@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

/* The Close Button */
.warranty-modal-close {
    color: var(--secondary-light-color);
    float: right;
    font-size: 45px;
    font-weight: 200;
}
  
.warranty-modal-close:hover,
.warranty-modal-close:focus {
    color: var(--danger-color);
    text-decoration: none;
    cursor: pointer;
}
  
.warranty-modal-header {
    padding: 2px 16px;
    background-color: var(--primary-dark-color);
    color: var(--secondary-light-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;
}

.warranty-modal-header h2 {
    font-size: 1.1em;
    font-weight: 400;
}
  
.warranty-modal-body {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
    height: 100%;
}

.warranty-modal-body p {
    color: var(--light-light-color);
    text-align: justify;
    font-size: var(--p-desktop-font-size);
    font-weight: 200;
}
  
.warranty-modal-footer {
    padding: 2px 16px;
    background-color: var(--primary-dark-color);
    color: var(--secondary-light-color);
    border-radius: 0 0 5px 5px;
}

.warranty-modal-footer h3 {
    display: flex;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 300;
}
/* ########################################## */
/* ############# END WARRANTY ############### */
/* ########################################## */

@media (max-width: 991px) {
    .footer {
        padding: 25px 20px;
    }
    
    .footer .content {
        flex-direction: column;
        gap: 32px;
    }

    .footer .content .col {
        max-width: 100%;
    }

    .footer .content .col .title {
        align-items: center;
    }
    
    .footer .content .col .infos {
        flex-direction: row;
    }

    .footer .content .col .infos.mobile {
        flex-direction: column;
    }

    .footer .content .col .infos .hours {
        flex-direction: row;
        gap: 10px;
    }
}