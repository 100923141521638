.contact {
    background: linear-gradient(180deg, #0A0F15 0%, rgba(10, 15, 21, 0.8) 100%), url('../../assets/imgs/contact/background.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
}

.contact .infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 467px;
}

.contact .infos .info {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.contact .infos .info .icon {
    color: var(--primary-light-color);
    font-size: 25px;
}

.contact .infos .info h2 {
    text-align: center;
}

.contact .infos .info a {
    text-decoration: none;
    color: var(--light-light-color);
    font-weight: 200;
    font-size: 16px;
    line-height: 26px;
    width: 25px;
    display: flex;
    justify-content: center;
}

.contact .infos .info h2 a {
    width: auto;
    text-align: left;
}

.contact .form {
    background: rgba(66, 66, 66, 0.5);
    border-radius: 5px;
    gap: 10px;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    width: 467px;
}

.contact .form .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact .form .action .primary-button {
    gap: 10px;
}

.contact .form .action .primary-button .fa-spinner {
    font-size: 25px;
    color: var(--secondary-color);
}

/* .contact .form .action .attach input {
    background: none;
    height: 100%;
    padding: 0;
} */

.contact form .error-msg {
    color: var(--danger-color);
}

.contact form .success-msg {
    color: var(--success-color);
}

@media (max-width: 991px) {
    .contact .form {
        width: 80%;
    }

    .contact .infos {
        width: auto;
    }

    .contact .infos .info a {
        font-size: 0.7em;
    }

    .contact .form .action {
        flex-wrap: wrap;
        gap: 32px;
    }

    .contact .form .action button {
        width: 150px;
    }
}

@media (max-width: 480px) {
    .contact .form {
        width: 100%;
    }
}