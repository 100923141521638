.article {
    background: var(--secondary-color);
    display: flex;
    justify-content: center;
    width: 100%;
}

.article .content {
    background: #fff;
    margin: 0px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 240px;
    gap: 32px;
    border-radius: 5px;
    position: relative;
    top: -160px;
    max-width: 1500px;
    width: 100%;
}

.article .content .author {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    padding: 0px 16px;
    width: 100%;
    max-width: 832px;
}

.article .content .author img {
    width: 56px;
    height: 56px;
    border-radius: 100px;
    max-width: 832px;
}

.article .content .author .author-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.article .content .author .author-text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--secondary-light-color);
}

.article .content .content-text {
    max-width: 832px;
}

.article .content .content-text p {
    margin-bottom: 24px;
    text-align: justify;
    hyphens: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--secondary-light-color);
}

.article .content .content-text img {
    width: 100%;
}

.article .content .content-text h1 {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 48px;
    line-height: 63px;
    color: var(--primary-dark-color);
}

.article .content .content-text h2,
.article .content .content-text h3 {
    margin-bottom: 16px;
    color: var(--primary-dark-color);
}

.article .content .content-text h2 {
    font-weight: 400;
    font-size: 36px;
    line-height: 47px;
}

.article .content .content-text h3 {
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
}

.article .content .social-share {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 832px;
}

.article .content .social-share .text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--secondary-light-color);
    text-align: justify;
    hyphens: auto;
}

.article .content .social-share .buttons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.article .content .cta-contact {
    width: 100%;
    max-width: 832px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.article .content .cta-contact .text {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.article .content .cta-contact .text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--secondary-light-color);
    text-align: justify;
    hyphens: auto;
}

.article .content .content-text .loading {
    display: flex;
    align-items: center;
    gap: 16px;
}

@media(max-width: 1239px) {
    .article .content {
        margin: 0px 64px;
        padding: 32px 140px;
    }
}

@media(max-width: 904px) {
    .article .content {
        margin: 0px 32px;
        padding: 32px 100px;
    }
}

@media(max-width: 509px) {
    .article .content {
        margin: 0px 16px;
        padding: 32px 40px;
        top: -400px;
    }

    .article .content .content-text h1 {
        font-size: 32px;
        line-height: 42px;
    }

    .article .content .content-text h2 {
        font-size: 28px;
        line-height: 36px;
    }
    
    .article .content .content-text h3 {
        font-size: 24px;
        line-height: 31px;
    }
}