/* @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap'); */

@font-face {
    font-family: "ultra-condensed";
    src: url('./fonts/UltraCondensedSansSerif.ttf');
    font-display: swap;
}

:root {
    --primary-color: #EAC87E;
    --primary-light-color: #FCE7AE;
    --primary-dark-color: #C4A560;
    --secondary-color: #1D1D1D;
    --secondary-light-color: #424242;
    --secondary-dark-color: #000000;
    --light-color: #000000;
    --light-light-color: #FAFBFF;
    --light-dark-color: #FAF5F5;
    --danger-color: #E55A5A;
    --success-color: #9BE577;

    --bg-darker: #05080b;
    --bg-lighter: #0A0F15;

    --blue-shaker-color: #334C6B;
    --cognac-color: #783118;
    --espresso-color: #170E10;
    --light-chocolate-color: #B97855;
    --moonshine-color: #D7DBD0;
    --pebble-grey-color: #BCBCBC;
    --pigeon-white-color: #FCFCFC;
    --saddle-color: #453b33;
    --shaker-cinder-color: #565352;
    --dark-grain-color: #705C4B;
    --dark-grey-gloss-color: #1B1C1F;
    --glossy-white-color: #FFFF;
    --light-grey-color: #4C4E4B;
    --white-oak-color: #A39D8E;

    --h2-desktop-font-size: 2em;
    --h3-desktop-font-size: 2em;
    --p-desktop-font-size: 1em;

    --h2-regular-font-weight: 300;
    --font-weight-regular: 300;
}

* {
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.primary-button {
  /* background: var(--primary-color);
  border-radius: 5px;
  border: none;
  padding: 15px 18px;
  width: 195px;
  height: 49px;
  color: var(--secondary-light-color);
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  cursor: pointer;
  transition: 0.8s; */
  background: var(--primary-color);
  padding: 18px 32px;
  text-transform: uppercase;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(23, 20, 31, 0.11), 0px 1px 3px rgba(23, 20, 31, 0.08);
  border: none;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: var(--secondary-light-color);
  transition: all .18s ease;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.primary-button.disabled {
  background: var(--primary-color);
  opacity: 0.65;
  cursor: auto;
}

.primary {
  color: var(--primary-color);
}

.light-1 {
  color: var(--light-light-color);
}

.regular {
  font-weight: 200;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

section {
  padding: 40px 100px;
}

/* ########################################## */
/* ############# START TITLE ################ */
/* ########################################## */
section .title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

section .title h2,
section .title h1 {
  color: var(--light-light-color);
  font-weight: var(--h2-regular-font-weight);
  font-size: 3.5em;
  font-family: 'ultra-condensed','Poppins', sans-serif;
  text-align: center;
}

section .title p {
  color: var(--light-light-color);
  font-weight: 200;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  max-width: 500px;
  margin-top: 8px;
}

section .title hr {
  background: var(--primary-color);
  width: 80px;
  height: 5px;
  border-radius: 5px;
  border: none;
}
/* ########################################## */
/* ############# END TITLE ################## */
/* ########################################## */

@media (max-width: 991px) {
  section {
    /* padding: 90px 20px 35px 20px; */
    padding: 32px 20px 32px 20px;
  }

  section .title {
    padding: 0px 20px 0px 20px;
  }
}