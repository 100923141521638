.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-lighter);
    gap: 45px;
}

/* .services .items.mobile {
    display: none;
} */

.services .items.mobile .swiper .swiper-button-prev,
.services .items.mobile .swiper .swiper-button-next {
    color: var(--primary-color);
}

.services .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
}

.services .items .item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* width: 842px; */
}

/* .services .items .item .image.kitchen {
    background: url('./imgs/kitchen.webp');
    width: 170px;
    height: 170px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    border: 1px solid rgba(234, 200, 126, 0.5);
    position: relative;
}

.services .items .item .image.bathroom {
    background: url('./imgs/bathroom.webp');
    width: 170px;
    height: 170px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    border: 1px solid rgba(234, 200, 126, 0.5);
    position: relative;
}

.services .items .item .image.flooring {
    background: url('./imgs/flooring.webp');
    width: 170px;
    height: 170px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    border: 1px solid rgba(234, 200, 126, 0.5);
    position: relative;
} */

.services .items .item .image .legend {
    position: absolute;
    left: 0;
    bottom: 0; 
    display: flex;
    align-items: center;
    gap: 5px;
}

.services .items .item .image .legend .icon {
    background: var(--secondary-color);
    width: 30px;
    height: 30px;
    border-radius: 0px 5px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services .items .item .image .legend .icon img {
    width: 30px;
    height: 30px;
}

.services .items .item .image .legend .description {
    color: var(--light-light-color);
    font-size: var(--p-desktop-font-size);
    font-weight: 600;
}

.services .items .item .info {
    display: flex;
    flex-direction: column;
    background: rgba(66, 66, 66, 0.2);
    /* border: 1px solid rgba(252, 231, 174, 0.2); */
    border-radius: 5px;
    gap: 10px;
    padding: 20px;
    width: 632px;
}

.services .items .item .info h3 {
    color: var(--primary-color);
    font-size: var(--h3-desktop-font-size);
    font-weight: var(--font-weight-regular);
}

.services .items .item .info p {
    color: var(--light-light-color);
    font-size: 16px;
    font-weight: 200;
    text-align: justify;
    hyphens: auto;
    /* text-justify: inter-word; */
    line-height: 26px;
}

.services .items .item .info a {
    color: var(--primary-light-color);
    text-decoration: none;
}

.services .items hr {
    width: 5px;
    height: 25px;
    background: var(--primary-dark-color);
    border-radius: 5px;
    border: none;
}

@media (max-width: 991px) {
    .services .items.desktop {
        display: none;
    }

    .services .items.mobile {
        /* display: block; */
        display: flex;
        flex-direction: column;
    }

    .services {
        gap: 35px;
    }

    .services .items {
        /* gap: 10px; */
        gap: 40px;
    }

    .services .items .swiper .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .services .items .item {
        flex-direction: column;
        width: 100%;
        gap: 0px;
        position: relative;
    }

    .services .items .item .image {
        z-index: 1;
    }

    .services .items .item .info {
        width: 100%;
        /* align-items: center; */
        position: relative;
        margin-top: -85px;
        padding-top: 100px;
        /* padding-left: 70px;
        padding-right: 70px; */
    }

    .services .items .item .info p {
        /* text-align: justify; */
        /* text-justify: inter-word; */
    }

    /* .services .items.mobile .servicesSwiper {
        width: 100%;
        height: 100%;
    }

    .services .items.mobile .servicesSwiper .services-pages {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 10px;
    }

    .services .items.mobile .servicesSwiper .services-pages .swiper-pagination {
        width: auto;
        color: var(--primary-color);
    } */
}