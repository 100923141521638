.gallery {
    /* background: var(--bg-darker); */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery .content {
    padding: 45px 0px;
    display: flex;
    flex-direction: column;
    gap: 45px;
    max-width: 1220px;
}

.gallery .content .category {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.gallery .content .category .category-name {
    display: flex;
    gap: 10px;
}

.gallery .content .category .category-name h3 {
    color: var(--light-light-color);
    font-weight: var(--font-weight-regular);
    font-size: 1.5em;
}

.gallery .content .category .category-name .underline hr {
    background: var(--primary-color);
    border-radius: 5px;
    border: none;
    height: 5px;
    width: 100%;
}

.gallery .content .category .items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.gallery .cta {
    display: flex;
    justify-content: center;
}