/* The Modal (background) */
.new-address-modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 130px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    /* overflow: auto; Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
  
/* Modal Content */
.new-address-modal-content {
    position: relative;
    /* background-color: var(--bg-lighter); */
    background: rgba(23, 25, 29);
    margin: auto;
    margin-bottom: 100px;
    padding: 0;
    border: 1px solid rgba(252, 231, 174, 0.2);
    width: 80%;
    /* height: 80%; */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}
  
@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

/* The Close Button */
.new-address-modal-close {
    color: var(--secondary-light-color);
    float: right;
    font-size: 45px;
    font-weight: 200;
}
  
.new-address-modal-close:hover,
.new-address-modal-close:focus {
    color: var(--danger-color);
    text-decoration: none;
    cursor: pointer;
}
  
.new-address-modal-header {
    padding: 2px 16px;
    background-color: var(--primary-dark-color);
    color: var(--secondary-light-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;
}

.new-address-modal-header h2 {
    font-size: 1.1em;
    font-weight: 400;
}
  
.new-address-modal-body {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
    height: 100%;
    align-items: center;
}

.new-address-modal-body p {
    color: var(--light-light-color);
    text-align: left;
    font-size: var(--p-desktop-font-size);
    font-weight: 200;
}

.new-address-modal-body p b {
    font-weight: 500;
    color: var(--primary-color);
}
  
.new-address-modal-footer {
    padding: 2px 16px;
    background-color: var(--primary-dark-color);
    color: var(--secondary-light-color);
    border-radius: 0 0 5px 5px;
}

.new-address-modal-footer h3 {
    display: flex;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 300;
}