.Thumbnail .item {
    width: 336px;
    height: 424px;
    background: rgba(66, 66, 66, 0.2);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.Thumbnail .item .image {
    width: 328px;
    height: 416px;
    border-radius: 5px;
    position: relative;
    /* border: 1px solid rgba(234, 200, 126, 0.5); */
    transition: .18s ease;
    cursor: pointer;
}

.Thumbnail .item.door {
    /* width: 336px; */
    height: 680px;
}

.Thumbnail .item.door .image {
    /* width: 328px; */
    height: 670px;
}

.Thumbnail .item.door .image:hover {
    width: 330px;
    height: 674px;
}

.Thumbnail .item .image:hover {
    width: 330px;
    height: 420px;
}

.Thumbnail .item.cover {
    background: none;
    width: 170px;
    height: 170px;
}

.Thumbnail .item.cover .image {
    width: 170px;
    height: 170px;
}

.Thumbnail .item.cover .image:hover {
    width: 170px;
    height: 170px;
    /* cursor: auto; */
}

.Thumbnail .item.no-cursor .image:hover {
    cursor: auto;
}

.Thumbnail .item .image .badge-container {
    position : absolute;
    left: 10px;
    top: 10px;
}

.Thumbnail .item .image .legend {
    position: absolute;
    left: 0;
    bottom: 0; 
    display: flex;
    align-items: center;
    gap: 5px;

    width: 100%;
    background: rgba(66, 66, 66, 0.8);
    border-radius: 0px 0px 5px 5px;
}

.Thumbnail .item .image .legend .icon {
    width: 30px;
    height: 30px;
    border-radius: 0px 5px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Thumbnail .item .image .legend .icon img {
    width: 30px;
    height: 30px;
}

.Thumbnail .item.antique-cream .image .legend .icon,
.Thumbnail .item.antique-cream-door .image .legend .icon {
    background: #e7e3d9;
}
.Thumbnail .item.blue-shaker .image .legend .icon {
    background: var(--blue-shaker-color);
}
.Thumbnail .item.cognac .image .legend .icon,
.Thumbnail .item.cognac-door .image .legend .icon {
    background: var(--cognac-color);
}
.Thumbnail .item.espresso .image .legend .icon {
    background: var(--espresso-color);
}
.Thumbnail .item.light-chocolate .image .legend .icon {
    background: var(--light-chocolate-color);
}
.Thumbnail .item.moonshine .image .legend .icon {
    background: var(--moonshine-color);
}
.Thumbnail .item.pebble-grey .image .legend .icon {
    background: var(--pebble-grey-color);
}
.Thumbnail .item.pebble-stone .image .legend .icon,
.Thumbnail .item.pebble-stone-door .image .legend .icon {
    background: #7c7c7c;
}
.Thumbnail .item.pigeon-white .image .legend .icon {
    background: var(--pigeon-white-color);
}
.Thumbnail .item.pure-white .image .legend .icon,
.Thumbnail .item.pure-white-door .image .legend .icon {
    background: #e4e6e5;
}
.Thumbnail .item.saddle .image .legend .icon {
    background: var(--saddle-color);
}
.Thumbnail .item.sage-green .image .legend .icon {
    background: #A0A594;
}
.Thumbnail .item.shaker-cinder .image .legend .icon,
.Thumbnail .item.shaker-cinder-door .image .legend .icon {
    background: var(--shaker-cinder-color);
}
.Thumbnail .item.shaker-cream .image .legend .icon,
.Thumbnail .item.shaker-cream-door .image .legend .icon {
    background: #f9f5e9;
}
.Thumbnail .item.shaker-dove .image .legend .icon,
.Thumbnail .item.shaker-dove-door .image .legend .icon {
    background: #ccc7c3;
}
.Thumbnail .item.shaker-black .image .legend .icon,
.Thumbnail .item.shaker-black-door .image .legend .icon {
    background: #222222;
}
.Thumbnail .item.shaker-honey .image .legend .icon,
.Thumbnail .item.shaker-honey-door .image .legend .icon {
    background: #CFBEAC;
}
.Thumbnail .item.slim-dove-white .image .legend .icon {
    background: #F1F2F4;
}
.Thumbnail .item.slim-white-oak .image .legend .icon {
    background: #B8A180;
}
.Thumbnail .item.oxford .image .legend .icon,
.Thumbnail .item.oxford-door .image .legend .icon {
    background: #afa290;
}
.Thumbnail .item.toffee .image .legend .icon,
.Thumbnail .item.toffee-door .image .legend .icon {
    background: #9a7b59;
}
.Thumbnail .item.torrance-white .image .legend .icon,
.Thumbnail .item.torrance-white-door .image .legend .icon {
    background: #e3e2e0;
}
.Thumbnail .item.dark-grain .image .legend .icon {
    background: var(--dark-grain-color);
}
.Thumbnail .item.dark-grey-gloss .image .legend .icon {
    background: var(--dark-grey-gloss-color);
}
.Thumbnail .item.glossy-white .image .legend .icon {
    background: var(--glossy-white-color);
}
.Thumbnail .item.light-grey .image .legend .icon {
    background: var(--light-grey-color);
}
.Thumbnail .item.white-oak .image .legend .icon {
    background: var(--white-oak-color);
}

.Thumbnail .item .image .legend .icon {
    background: var(--secondary-color);
}

.Thumbnail .item .image .legend .description {
    color: var(--light-light-color);
    font-size: 0.8em;
    font-weight: 600;
}