.badge {
    /* width: 104px; */
    padding: 0px 16px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    /* background: linear-gradient(264deg, #EAC87E 0%, rgba(234, 200, 126, 0.80) 100%); */
    background: var(--primary-color);
    position: absolute;
    left: 0px;
    top: 0px;
}

.shadow {
    width: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    /* background: linear-gradient(264deg, #EAC87E 0%, rgba(234, 200, 126, 0.80) 100%); */
    background: var(--primary-color);
    filter: blur(17.5px);
}

.badge p {
    color: var(--light-light-color);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}