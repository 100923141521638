.page-not-found {
    background: linear-gradient(180deg, #0A0F15 -3.92%, rgba(10, 15, 21, 0.5) 114.9%), url('./imgs/banner.webp');
    padding: 0px 100px 100px 100px;
    position: relative;
    width: 100%;
    /* min-height: 100vh; */
    height: 1100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.page-not-found::after {
    content: "";
    background-image: linear-gradient(180deg, #0A0F15 -3.92%, rgba(10, 15, 21, 0.5) 114.9%), url('./imgs/banner.webp');
    filter: blur(4px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    /* min-height: 100vh; */
    height: 1100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.page-not-found .content {
    display: flex;
    justify-content: center;
    padding-top: 320px;
    max-width: 1000px;
}

.page-not-found .content .text {
    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 1;
}

.page-not-found .content .text .title {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.page-not-found .content .text .title hr {
    height: 5px;
    width: 195px;
    background: var(--primary-color);
    border: none;
    border-radius: 5px;
}

.page-not-found .content .text .title h1 {
    color: var(--light-light-color);
    /* text-transform: uppercase; */
    font-weight: 600;
    font-family: 'ultra-condensed';
    /* font-size: 3.8em; */
    font-size: 64px;
    line-height: 70px;
}

.page-not-found .content .text .subtitle {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.page-not-found .content .text .subtitle p {
    font-size: 24px;
    line-height: 38px;
    font-weight: 200;
}

.page-not-found .content .text .subtitle h2 {
    /* font-size: 1.2em; */
    font-size: 18px;
    line-height: 29px;
    font-weight: 300;
}

.page-not-found .content .text .list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* padding: 35px 0; */
}

.page-not-found .content .text .list .item {
    display: flex;
    align-items: center;
    gap: 12px;
}

.page-not-found .content .text .list .item hr {
    height: 5px;
    width: 20px;
    background: var(--primary-color);
    border: none;
    border-radius: 5px;
}

.page-not-found .content .text .list .item p {
    font-size: 16px;
    font-weight: 200;
    line-height: 26px;
    color: var(--light-light-color);
}

.page-not-found .content .text .list .item h2 {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    color: var(--light-light-color);
}

.page-not-found .content .text .numbers {
    display: flex;
    gap: 40px;
    padding: 35px 0;
}

.page-not-found .content .text .numbers .ad {
    display: flex;
    flex-direction: column;
}

.page-not-found .content .text .numbers .ad .number {
    font-size: 2.5em;
    font-weight: 300;
}

@media (max-width: 1439px) {
    .page-not-found {
        height: 1000px;
    }
    
    .page-not-found::after {
        height: 1000px;
    }
}

@media (max-width: 1239px) {
    .page-not-found .content {
        padding-top: 280px;
    }
}

@media (max-width: 904px) {
    .page-not-found .content {
        padding-top: 260px;
    }
}

@media (max-width: 599px) {
    .page-not-found {
        height: 860px;
        padding: 0px 32px;
    }
    
    .page-not-found::after {
        height: 860px;
    }

    .page-not-found .content {
        padding-top: 160px;
    }

    .page-not-found .content .text .title h1 {
        font-weight: 400;
        font-size: 48px;
        line-height: 53px;
    }

    .page-not-found .content .text .subtitle h2 {
        font-size: 18px;
        line-height: 29px;
    }

    .page-not-found .content .text .list {
        gap: 12px;
    }
}