.articles {
    display: flex;
    flex-direction: column;
    gap: 45px;
}

.articles .container {
    display: flex;
    justify-content: center;
    gap: 64px;
    flex-wrap: wrap;
}