.FileInput input {
    background: none;
    height: 100%;
    padding: 0;
    color: var(--primary-dark-color);
}

.FileInput input:focus {
    border: none;
}

.FileInput input[type="file"]::file-selector-button {
    background: none;
    border: 2px solid var(--primary-dark-color);
    border-radius: 110px;
    padding: 15px 18px;
    color: var(--primary-dark-color);
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
}

.FileInput .error-msg {
    color: var(--danger-color);
}