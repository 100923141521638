header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* padding: 40px 100px; */
    padding: 10px 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: 0.8s;
    gap: 20px;
    background: var(--bg-darker);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

/* header.sticky {
    background: var(--bg-darker);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    padding: 10px 100px;
} */

header .logo {
    height: 70px;
    min-width: 70px;
    transition: 0.8s;
}
/* header .logo {
    height: 200px;
    min-width: 200px;
    transition: 0.8s;
} */

/* header .logo.sticky {
    height: 70px;
    min-width: 70px;
} */

header .logo img {
    height: 100%;
    min-width: 100%;
}

header .phone {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 70px;
}

header .social {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 70px;
}

header .phone a,
header .social a {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 1em;
    font-weight: 200;
    display: flex;
    align-items: center;
}

header .phone a i,
header .social a i {
    font-size: 25px;
    /* width: 70px; */
}

header .menuToggle {
    display: none;
}

header .navigation {
    display: flex;
    gap: 20px;
    height: 70px;
    align-items: center;
}

header .navigation li {
    list-style: none;
}

header .navigation a {
    text-decoration: none;
    color: var(--light-light-color);
    font-size: 1em;
    font-weight: 200;
}

header .navigation a:hover {
    color: var(--primary-color);
}

@media (max-width: 1260px) {
    header .phone .text {
        display: none;
    }
}

@media (max-width: 1130px) {
    header .phone {
        margin-right: 25px;
    }
}

@media (max-width: 991px) {
    header,
    header.sticky {
        padding: 10px 20px;
    }

    /* header .logo {
        height: 100px;
        min-width: 100px;
    } */

    /* header .phone {
        height: 100px;
        transition: 0.8s;
    } */
    header .phone {
        height: 70px;
    }

    /* header.sticky .phone {
        height: 70px;
    } */

    header .phone a i {
        font-size: 30px;
        width: 50px;
        text-align: center;
    }

    /* header.sticky .SearchBar {
        height: 70px;
    } */
    header .SearchBar {
        height: 70px;
    }
    
    header .navigation,
    header .phone .text {
        display: none;
    }

    header .menuToggle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: var(--primary-color);
        cursor: pointer;
    }

    header .menuToggle i {
        font-size: 30px;
        width: 50px;
    }

    header .navigation.active {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--bg-darker);
        z-index: -1;
    }
}