.agreement {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-lighter);
    gap: 45px;
    padding-top: 250px;
}

.agreement form {
    display: flex;
    flex-direction: column;
    background: rgba(66, 66, 66, 0.2);
    /* border: 1px solid rgba(252, 231, 174, 0.2); */
    border-radius: 5px;
    gap: 10px;
    padding: 96px;
    width: 100%;
    position: relative;
    max-width: 1440px;
}

.agreement form h2 {
    color: var(--primary-color);
    font-size: var(--h3-desktop-font-size);
    font-weight: var(--font-weight-regular);
    text-align: center;
}

.agreement form p {
    color: var(--light-light-color);
    font-size: 16px;
    line-height: 26px;
    font-weight: 200;
    text-align: justify;
    text-justify: inter-word;
    hyphens: auto;
}

.agreement form .required {
    color: var(--primary-dark-color);
}

.agreement form input {
    color: var(--primary-dark-color);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 5px;
    border: none;
    font-size: 0.8em;
    font-weight: 200;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-weight: bold;
}

.agreement form .cabinets-name {
    width: 300px;
}

.agreement form .customers-name {
    width: 400px;
}

.agreement form input:focus {
    border: 1px solid var(--primary-dark-color);
    outline: none;
}

.agreement form input::placeholder {
    color: var(--primary-dark-color);
}

.agreement form .cta {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.agreement hr {
    width: 5px;
    height: 25px;
    background: var(--primary-dark-color);
    border-radius: 5px;
    border: none;
}

.names-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    margin-top: 35px;
}

.signature-flex {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 15px;
    margin-top: 35px;
}

.wrapper {
    position: relative;
    width: 406px;
    height: 206px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: rgba(255, 255, 255, 1);
}

.clear-signature-pad {
    width: 406px;
}

.signature-pad {
    position: absolute;
    left: 0;
    top: 0;
    width: 400px;
    height: 200px;
}

.agreement form #verification,
.agreement form .cta #error-msg {
    color: var(--danger-color);
}

.agreement form #success-msg,
.agreement form #loading-msg {
    color: var(--success-color);
}

@media (max-width: 991px) {
    .agreement {
        gap: 35px;
        padding: 150px 20px 35px 20px;
    }

    .agreement form {
        padding: 35px 20px 35px 20px;
    }

    .signature-flex {
        width: 300px;
    }

    .wrapper {
        width: 280px;
    }

    .clear-signature-pad {
        width: 280px;
    }

    .signature-pad {
        width: 300px;
    }

    .agreement form .customers-name {
        width: 300px;
    }
}