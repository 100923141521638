.heading-article {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 576px;
    background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%), url("../../assets/imgs/heading.webp");
}

.heading-article::after {
    content: "";
    background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 100%), url("../../assets/imgs/heading.webp");
    filter: blur(1px);
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 576px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}