.testimonials {
    background: var(--bg-lighter);
}

.testimonials .reviews {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.testimonials .reviews .reviews-Swiper {
    width: 100%;
    /* height: 80%; */
}

.testimonials .reviews .reviews-Swiper .swiper-slide {
    display: flex;
    justify-content: center;
    height: auto;
    width: 100%;
}

/* .testimonials .reviews .reviews-Swiper .reviews-pages {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
}

.testimonials .reviews .reviews-Swiper .reviews-pages .swiper-pagination {
    width: auto;
    color: var(--primary-color);
} */

.testimonials .reviews .reviews-Swiper .swiper-button-prev,
.testimonials .reviews .reviews-Swiper .swiper-button-next {
    color: var(--primary-color);
}

.testimonials .reviews .review {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    padding: 0 64px 34px 64px;
    margin-bottom: 10px;
    background: rgba(66, 66, 66, 0.2);
    /* border: 1px solid rgba(252, 231, 174, 0.2); */
    border-radius: 5px;
    position: relative;
    overflow: visible;
    margin-top: 29px;
}

.testimonials .reviews .review img {
    position: absolute;
    top: -29px;
    width: 58px;
    height: auto;
}

.testimonials .reviews .review .stars {
    padding-bottom: 10px;
}

.fa.fa-star.checked {
    color: var(--primary-light-color);
}

.testimonials .reviews .review p {
    text-align: center;
}

.testimonials .reviews .review .name {
    padding: 34px 0 5px 0;
    font-size: 1em;
    color: var(--primary-color);
    font-weight: 600;
}

.testimonials .reviews .review .text {
    font-size: 16px;
    line-height: 26px;
    font-weight: 200;
    color: var(--light-light-color);
    text-align: justify;
    hyphens: auto;
}

.testimonials .reviews .review .see-more {
    cursor: pointer;
    color: var(--primary-light-color);
}

.testimonials .reviews .overall {
    text-align: center;
    margin-top: 15px;
}

.testimonials .reviews .overall p {
    color: var(--light-light-color);
    font-weight: 200;
}

.testimonials .reviews .overall p,
.testimonials .reviews .overall span {
    font-size: 1em;
}

.testimonials .reviews .overall .link {
    padding-top: 5px;
}

.testimonials .reviews .overall .link a {
    font-size: 0.9em;
    text-decoration: none;
    color: var(--primary-light-color);
    font-weight: 200;
}

@media (max-width: 991px) {
    .testimonials .reviews .review {
        width: 100%;
        padding: 0 60px 34px 60px;
    }

    .testimonials .reviews .review .text {
        /* text-align: left; */
    }
}