.card1 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 259px;
}

.card1 .image-container {
    width: 100%;
    height: 380px;
}

.card1 .text {
    display: flex;
    flex-direction: column;
    gap: 9px;
    width: 100%;
}

.card1 .text h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    align-items: center;
    color: var(--primary-color);
}

.card1 .text p {
    font-weight: 300;
    font-size: 16px;
    line-height: 27px;
    align-items: center;
    color: var(--light-dark-color);
}

.card1 .text a {
    text-decoration: none;
}

